import React from 'react'

const Copy: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1666 2H3.16663V10.6667"
        stroke="currentColor"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="currentColor"
        d="M5.83325 4.66675H13.8333V12.6667C13.8333 13.0204 13.6928 13.3595 13.4427 13.6096C13.1927 13.8596 12.8535 14.0001 12.4999 14.0001H7.16659C6.81296 14.0001 6.47383 13.8596 6.22378 13.6096C5.97373 13.3595 5.83325 13.0204 5.83325 12.6667V4.66675Z"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Copy
